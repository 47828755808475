* {
  padding:0;
  margin:0;
  box-sizing:border-box;
  font-family:'Josefin Sans'
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
{
  padding:0;
  margin:0;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}
.navbar-toggler 
{
  background-color:black;
  margin-right:20px;
}

/*Header Styles*/

.header 
{
   width:100%;
   height:100px;
   margin:0;
   background:#f7f9fb !important;
   box-shadow:0 1px 8px rgb(0 0 0 / 10%) !important;
}
.navlink 
{
  color:black;
  font-size:20px;
  margin-right:20px;
  padding:10px 10px;
  text-decoration: none;
}
.navlink:hover 
{
  color:black;
}


/* Home Page Styles*/

/* Promo Box*/

/* Delievery Section Styles*/
.delievery_section 
{
   width:100%;
   display:flex;
   flex-direction: row;
}
.delievery_text_box 
{
  width:40%;
  height:400px;
  padding:40px 40px;
}
.text-header 
{
    font-size:35px;
    font-weight: bold;
}
.text-para
{
   width:500px;
   font-size:20px;
}
.delievery_photo_container
{
  width:60%;
  height:400px;
  text-align: center;
  margin:auto auto;
  padding:40px 40px;
}
.big-image 
{
   width:250px;
   height:250px;
}
/* Image Gallery Styles*/
.heading
{
  font-size:40px;
  text-align: center;
  font-weight:bold;
  margin:50px auto;
}
/* Services Section Styles*/

.service_section
{
  width:100%;
  display:flex;
  flex-wrap:wrap;
  flex-direction: row;
}
.service_box 
{
   width:350px;
   height:400px;
   margin-top:40px;
   /* border:2px solid black; */
   box-shadow:0 1px 8px rgb(0 0 0 / 10%) !important;
   margin:auto auto;
   border-radius:20px
}
.service_img_box
{
  text-align:center;
  margin:20px auto;
}
.service-text-box 
{
   padding:20px 20px;
   text-align: center;
}
.service-header 
{
  padding:10px 10px;
  font-size:35px;
}
.service-para
{
   font-size:16px;
}
.service-image 
{
   width:80px;
   height:80px;
}
/* Signup Form Styles*/

.signup_container
{
   width:300px;
   display: flex;
   flex-direction: column;
   height:600px;
   border:5px solid whitesmoke;
   border-radius:40px;
   position:relative;
   top:50%;
   left:50%;
   transform:translate(-50%,10%);
}
/* Account Navbar Styles*/
.logo-text 
{
  width:100px;
}
.user-account-logo
{
  width: 10%;
  height: 50px;
  position: relative;
  float: right;
  margin-top: -70px;
  border-radius: 50%;
}
.view-orders 
{
  color:black;
  text-decoration: none;
}
.view-orders:hover 
{
   color:black;
}
/* Restaurant Card Styles*/
.restaurant-card-container
{
  display:flex;
  width:100%;
  flex-wrap:wrap;
  
}
.view-menu-btn 
{
   width:150px;
   margin:5px 0px;
   height:40px;
   background:#22211f;
   color:white;
   border:none;
   border-radius:20px;
}
/* Login Form Styles*/

.form_container
{
   width:300px;
   display: flex;
   flex-direction: column;
   height:400px;
   border:5px solid whitesmoke;
   border-radius:40px;
   position:relative;
   top:50%;
   left:50%;
   transform:translate(-50%,20%);
}

/* Form Styles*/

input:focus 
{
   border:none;
}
input[type="text"]
{
   width:200px;
   margin:auto auto;
   border:none;
   padding:10px 10px;
   border:2px solid #96deda;
   border-radius:10px;
   height:40px;

}
input[type="password"]
{
   width:200px;
   height:40px;
   border-radius:10px;
   margin:auto auto;
   border:none;
   padding:10px 10px;
   border:2px solid #96deda;
}
.image-container 
{
   text-align: center;
}
.input-container 
{
   text-align: center;
   margin-top:20px;
}
.form 
{
   margin:50px auto;
}
.submit_btn 
{
   width:200px;
   padding:10px 10px;
   background:#50c9c3;
   color:white;
   border:none;
   border-radius:10px;
   height:40px;
   cursor:pointer;
}

/* Footer Styles*/
.footer 
{
  display: flex;
  flex-direction: row;
  width:100%;
  left: 0;
  bottom: 0;
  background:#f7f9fb;
  height:300px;
  margin-top:100px;
}
.footer_logo_box 
{
  width:50%;
  height: 200px;
  padding:50px 50px;
}
.social_media_box 
{
  width:50%;
  height:200px;
  margin:100px 50px;
  text-align: center;
  padding:10px 10px;
}
.social-media-icon
  {
    font-size:50px;
    margin-right:30px; 
    margin-top: 30px
  }
.footer-text 
{
  padding:10px 10px;
  font-size:16px;
  width:auto;
}
.phone-icon 
{
   font-size:16px;
   padding:10px 10px;
}
/* Cart Box Styles*/
.cart_box 
{
   position:fixed;
   float:right;
   background:#22211f;
   right:40px;
   bottom:20px;
   width:50px;
   height:50px;
   cursor:pointer;
   border-radius:50%;
}
.cart_quantity
{
  width:20px;
  text-align: center;
  height:20px;
  background:#fff;
  border-radius:50%;
  position:absolute;
  margin-top:-50px;
  margin-left:35px;
}
/* Product Card Styles*/
.product-container
{
  
    margin-top: 50px;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
}
.main 
{
  width:100%;
  display: flex;
  flex-direction:row;
}
.box 
{
   margin-top:40px;
   width:400px;
   border:2px solid whitesmoke;
   padding:10px 10px;
   border-radius: 20px;
   height:320px;
   margin:20px auto;
}
.item-info
{
  height:200px;
  /* border:2px solid black; */
  width:100%;
}
.photo_container 
{
  width:35%;
  height:100%;
  display:inline-block;
  padding:10px 10px;
}
.item-details 
{
  display:inline;
  width:65%;
  float:right;
  height:100%;
  /* border:2px solid black; */
}
.item-title
{
   font-size:16px;
   font-weight:bold;
   padding:5px 5px;
}
.item-desc 
{
   font-size:14px;
   padding:5px 5px;
}
.item-price
{
  font-size:15px;
  padding:5px 5px;
  color: #232323;
  font-weight:bold;
}
.quantity_box 
{
    width:100px;
    height:30px;
    float:left;
    border:2px solid whitesmoke;
    border-radius:20px;
    display:flex;
    flex-direction: row;
    margin:50px 15px;
}
.quantity_btn 
{
   border:none;
   background:none;
}
.btn-container 
{
  width:100%;
  height:150px;
  /* border:2px solid black; */
}
.addtocart 
{
    border:none;
    background:#eb0029;
    color:white;
    font-size:12px;
    cursor:pointer;
    width:100px;
    float:right;
    margin:50px 10px;
    border-radius:20px;
    height:30px;    
}
/* Error Component Style*/
.error-box-container
{
   max-width:500px;
   margin:80px auto;
   height:400px;
}
.error-img-container
{
  text-align: center;
  margin:10px auto;
}
.error-img 
{
  max-width:300px;
  max-width:350px;
  margin:auto auto;
}
/* View Order Styles*/

.no-order-container
{

   max-width:500px;
   margin:80px auto;
   height:400px;
}
.no-order-img
{
   max-width:300px;
   max-height:350px;
   margin:auto auto;
}
.no-order-img-container 
{
   text-align: center;
   margin:10px auto;
}
/* Cart Box Styles*/

.empty-cart-text 
{
  font-size:20px;
  font-weight:bold;
  text-align: center;
}
.empty-cart-img 
{
   width:250px;
   height:250px;
   margin:auto auto;
}
.cart-item-box 
{
    width:100%;
    height:110px;
    display:flex;
    flex-direction: row;
    padding:5px 5px;
    border:4px solid whitesmoke;
    border-radius:20px;
    margin-top:5px;
}
.total-price 
{
    font-size:30px;
    color:#2d2e2e;
    margin-top:40px;
    text-align: center;
}
.checkout_btn_container 
{
   width:100%;
   margin-top:40px;
   text-align: center;
}
.checkout_btn 
{
   width:200px;
   border-radius:20px;
   cursor:pointer;
   height:40px;
   background:#22211f;
   color:white;
   border:none;
   text-align: center;
   font-size:20px;
}
.cart-product-imgbox
{
   width:30%;
   height:100%;
   text-align: center;
}
.cart-product-info
{
   width:60%;
   height:100%;
}
.cart-product-title 
{
    font-size:13px;
    padding:2px 2px;
    color:#eb0029;
    font-weight:bold;
}
.cart-product-quantity
{
   font-size:13px;
   padding:2px 2px;
   color:#2d2e2e;
   font-weight:bold;
}
.cart-product-subtotal
{
   font-size:16px;
   padding:2px 2px;
   color:#2d2e2e;
   font-weight:bold;
}
.address_textarea
{
  text-align: center;
  padding:10px 10px;
  height:150px;
  width:250px;
  box-shadow:0 1px 8px rgb(0 0 0 / 10%) !important;
  border:none;
  border-radius:20px;
}
.payment-success-container
{
   width:100%;
   height:500px;
   text-align: center;
}
.payment-success-img 
{
  width:400px;
  height:300px;
  margin:20px 0px;
}
/* Shaking Effect*/

.effect 
{
   animation:shake 1s;
}
@keyframes shake{
   0%{
     transform: translate(0px 0px)
   }
   25%{
     transform: translate(10px,10px)
   }
     
   50%{
     transform:translate(0px,0px)
   }
   75%{
      transform:translate(-10px,-10px)
    }
   100%{
     transform: translate(0px,0px)
   }
 }
/* View Orders Component Styles */
.order-list-container 
{
   display:flex;
   flex-direction: column;
}
.order-item-container 
{
  width:100%;
  margin:auto auto;
   height:80px;
   display:flex;
   flex-direction:row;
   margin-top:20px;
   border-radius:20px;
   box-shadow:0 1px 8px rgb(0 0 0 / 10%) !important;
}
 /*ADMIN PANEL STYLES*/
   /* Restaurant Card Styles*/

   .add-container 
   {
     width:100px;
     height:100px;
     border-radius: 50%;
     background:#232323;
     margin:auto auto;
   }
   .add-icon 
   {
    color:white;
    font-size: 50px;
    margin:20px auto;
    cursor:pointer;
   }
   .delete-icon 
   {
     width:100px;
     height:30px;
     padding:10px 10px;
     cursor:pointer;
   }
   .manage-orders-link 
   {
     color:white;
     text-decoration: none;
   }
   .manage-orders-link:hover 
   {
     color:white;
   }
@media (max-width: 800px) {

  .payment-success-img 
  {
    width:300px;
    height:200px;
    margin:20px 0px;
  }
  .promo_box
  {
    background-size: contain;
  }

  .delievery_section{
    flex-direction:column;
  }
  .delievery_photo_container
  {
     height:auto;
  }
  .delievery_text_box
  {
     width:100%;
  }
  .footer 
  {
     flex-direction: column;
     height:450px;
  }
  .footer_logo_box
  {
    width:100%;
  }
  .social_media_box
  {
     margin:150px 0px;
     width:100%;
     text-align: center;
  }
  .big-image
  {
    width:180px;
    height:180px;
  }
  .social-media-icon
  {
    font-size:50px;
    margin-right:10px; 
    margin-top: 30px
  }
  .user-account-logo
  {
    width:25%;
  }
  .text-para
  {
    width:auto;
  }
}